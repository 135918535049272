import React from 'react';

const Loader = () => {
    return (
        <div className="fixed z-[2000] w-full h-full flex justify-center items-center bg-black/40 backdrop-blur-sm">
            <div className="radial-progress text-primary animate-spin" style={{"--value":50} as React.CSSProperties}/>
        </div>
    );
};

export default Loader;

import React, { useState } from 'react';
import Collapse from "./Collapse";
import TextInput from "./TextInput";

const GroupItem = (props: GroupsItemProps) => {

  const [confirmStep, setConfirmStep] = useState(false);

  function onDeleteButton() {
    if (!confirmStep) {
      setConfirmStep(true)
      return
    }
    props.deleteGroupItem(props.formValue.id)
  }

  function onChange(event: any) {
    let name = event.target.name
    let value = event.target.value

    console.log(`${name} => ${value}`)

    props.onChange(props.formValue.id, name, value)
  }

  function getValue(key: any) {
    // @ts-ignore
    return GroupType[key]
  }

  return (
    <Collapse openByDefault={props.openByDefault} header={props.formValue.id + ". " + props.formValue.theme}>

      <div className="flex flex-col justify-around items-center bg-base-300 rounded-b-2xl">


        <form className="px-2 sm:px-6  w-full max-w-6xl">

          <TextInput value={props.formValue.theme} onChange={onChange} name="theme" labelRT="Thema"
            placeholder="Muster Thema" />

          <div className="form-control px-1">
            <label className="label cursor-pointer justify-start gap-5">

              <input checked={props.formValue.music} name="music" type="checkbox"
                className="checkbox checkbox-primary"
                onChange={() => props.onChange(props.formValue.id, "music", !props.formValue.music)} />
              <span className="text-md">Eigene Musik</span>
            </label>
          </div>

          <div className="px-2 pb-2">
            <span className="label-text">Typ</span>
            {Object.keys(GroupType).filter(i => !new RegExp('^[0-9]', 'i').test(i))
              .map(radio => (
                <div key={radio} className="form-control ">

                  <div className=" py-0.5">
                    <label className="flex  gap-3 cursor-pointer">
                      <input type="radio" name="groupType" value={radio}
                        checked={radio === props.formValue.groupType}
                        className="radio checked:bg-primary"
                        onChange={onChange} />
                      <span className="text-md">{getValue(radio)}</span>
                    </label>
                  </div>

                </div>
              ))}
          </div>
          {getValue(props.formValue.groupType) !== GroupType.FootGroup ?
            <>
              <TextInput type="text" value={props.formValue.lengthCount} onChange={onChange}
                name="lengthCount"
                labelRT="Wagenlänge" placeholder="999 Meter" />
              <div className="flex">
                <TextInput type="text" value={props.formValue.kowadi} onChange={onChange}
                  name="kowadi"
                  labelRT="KOWADI Ordner" placeholder="99 " />
                <TextInput type="text" value={props.formValue.self} onChange={onChange} name="self"
                  labelLT="Eigene Ordner" placeholder="99 " />
              </div>
            </>
            :
            <TextInput type="text" value={props.formValue.lengthCount} onChange={onChange}
              name="lengthCount"
              labelRT="Personenzahl" placeholder="99999 Personen" />
          }
        </form>

        <div className="px-2 sm:px-6 w-full max-w-6xl space-y-1">

          <button className="btn btn-outline btn-info p-1 w-full flex justify-center gap-2 items-center"
            onClick={() => props.reorderGroupItems(props.formValue.id, "up")}>

            <svg className="h-5 text-info" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512" fill='currentColor'>
              <path d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z" />
            </svg>

            <span className='w-40'>Hoch bewegen</span>
          </button>

          <button className="btn btn-outline btn-error p-1 w-full"
            onClick={onDeleteButton}>
            <div className="flex justify-around items-center gap-1.5">
              <svg className="h-5" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                viewBox="0 0 1000 1000" fill='currentColor'>
                <g>
                  <path
                    d="M188.1,869.7c0,65,52.4,120.3,117,120.3h389.9c64.6,0,117-55.4,117-120.3l78-630.1H110.1L188.1,869.7z M622.8,361.8h76.5v506h-76.5V361.8z M453.7,361.8h92.5v506h-92.5V361.8L453.7,361.8z M300.7,361.8h76.5v506h-76.5V361.8z M870.4,86.5H616.9c0,0-17.5-76.5-39-76.5H422c-21.5,0-39,76.5-39,76.5H129.6c-32.3,0-58.5,28.2-58.5,60.7c0,32.5,0,61.5,0,61.5h857.8c0,0,0-29,0-61.5C928.9,114.8,902.7,86.5,870.4,86.5z" />
                </g>
              </svg>
              {confirmStep ?
                <span className="w-40">Sind Sie sich sicher?</span>
                :
                <span className="w-40">Löschen</span>
              }
            </div>
          </button>

          <button className="btn btn-outline btn-info p-1 w-full flex justify-center gap-2 items-center"
            onClick={() => props.reorderGroupItems(props.formValue.id, "down")}>
            <svg className="h-5 text-info" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512" fill='currentColor'>
              <path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
            </svg>
            <span className='w-40'>Runter bewegen</span>
          </button>

        </div>

      </div>
    </Collapse>
  );
};

export default GroupItem;

interface GroupsItemProps {
  formValue: GroupsFormValues,
  onChange: any,
  reorderGroupItems: any,
  openByDefault?: boolean,
  deleteGroupItem: any
}

export interface GroupsFormValues {
  id: number,
  theme: string,
  music: boolean,
  lengthCount: string,
  groupType: GroupType,
  kowadi: number,
  self: number
}

export enum GroupType {
  FootGroup = "Fußgruppe",
  SutlerWagon = "Marketenderwagen",
  GunTrain = "Kanonenzug",
  BandWagon = "Festwagen",
}

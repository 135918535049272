import React, { useState } from 'react';
import LoginUserForm from "../components/LoginUserForm";
import Collapse from "../components/Collapse";
import SaveModal from "../components/SaveModal";
import { DataProvider } from "../components/DataProvider";
import VehicleApprovalForm from "../components/VehicleApprovalForm";
import ClubForm from "../components/ClubFrom";
import { GroupType } from "../components/GroupItem";
import GroupsTable from "../components/GroupsTable";
import Header from "../components/Header";
import TextInput from "../components/TextInput";
import Loader from "../components/Loader";
import Footer from "../components/Footer";

const Main = () => {

  interface GroupsFormValues {
    id: number,
    theme: string,
    lengthCount: string,
    groupType: GroupType,
    kowadi: number,
    self: number
  }

  const [loginUser, setLoginUser] = useState({
    firstName: "",
    lastName: "",
    street: "",
    houseNumber: "",
    city: "",
    phoneNumber: "",
    email: ""
  });
  const [clubInfo, setClubInfo] = useState({
    clubName: "",
    owner: "",
    city: ""
  });
  const [vehicleApproval, setVehicleApproval] = useState({
    firstName: "",
    lastName: "",
    street: "",
    houseNumber: "",
    city: "",
    phoneNumber: "",
    email: ""
  });
  //@ts-ignore
  const [groupList, setGroupList] = useState([
    {
      id: 1,
      theme: "A",
      lengthCount: "B",
      music: true,
      groupType: "FootGroup",
      kowadi: "1",
      self: "2"
    },
  ])
  const [alertList, setAlertList] = useState([{ ok: true, msg: "-DONT-LOAD-ME-" }])
  const [verifyCode, setVerifyCode] = useState("")

  const [activeItemsByDefault, setActiveItemsByDefault] = useState([])
  const [verified, setVerified] = useState(false)
  const [loading, setLoading] = useState(false);

  let dataProvider = new DataProvider(
    loginUser, setLoginUser,
    clubInfo, setClubInfo,
    vehicleApproval, setVehicleApproval,
    groupList, setGroupList,
    verifyCode,
    setActiveItemsByDefault,
    alertList, setAlertList,
    verified, setVerified,
    setLoading
  )

  return (
    <>
      {loading ?
        <Loader />
        :
        ""
      }
      <Header title="AKK Anmeldung" email={loginUser.email} />
      <div className="p-1 sm:p-2 lg:px-24">
        {!verified ?
          <>
            <TextInput name="email" value={loginUser.email}
              onChange={(e: any) => dataProvider.handleLoginUserFormChange(e)}
              type="email" labelRT={"E-Mail"}
              placeholder="max@muster-mail.de" />
            <SaveModal uploadChanges={() => dataProvider.pullData()}
              sendVerificationCode={() => dataProvider.sendVerification(loginUser.email)}
              validateData={() => dataProvider.validateData()}
              verifyCode={verifyCode} setVerifyCode={setVerifyCode}
              infoText="Klicke um die Anmeldung zu starten: " openButton="Anmeldung starten" />
          </>
          :

          <>
            <Collapse header="Verantwortlicher für die Anmeldung">
              <LoginUserForm formValues={loginUser}
                onChange={(e: any) => dataProvider.handleLoginUserFormChange(e)} />
            </Collapse>
            <Collapse header="Verein">
              <ClubForm formValues={clubInfo}
                onChange={(e: any) => dataProvider.handleClubFormChange(e)} />
            </Collapse>
            <Collapse header="Verantwortlicher für die Abnahme">
              <VehicleApprovalForm formValues={vehicleApproval}
                onChange={(e: any) => dataProvider.handleVehicleApprovalFormChange(e)} />
            </Collapse>

            <GroupsTable openItemsByDefault={activeItemsByDefault} //@ts-ignore 
              formValues={groupList}
              onChange={(id: number, name: string, value: any) => dataProvider.handleGroupsFormChange(id, name, value)}
              reorderGroupItems={(id: number, move: "up" | "down") => dataProvider.reorderGroupItems(id, move)}
              deleteGroupItem={(id: number) => dataProvider.deleteGroupItem(id)}
              addEmptyGroupItem={() => dataProvider.addEmptyGroupItem()} />


            <div className="w-1 h-24" />

            <div className="p-2 fixed left-0 bottom-0 w-full p-1">


              <SaveModal uploadChanges={() => dataProvider.uploadChanges()}
                sendVerificationCode={() => dataProvider.sendVerification(loginUser.email)}
                validateData={() => dataProvider.validateData()}
                getAll={dataProvider.buildJSON()} verifyCode={verifyCode}
                setVerifyCode={setVerifyCode}
                infoText="Klicken um Änderungen zu speichern" openButton="Änderungen speichern" />
            </div>
          </>

        }
      </div>
      <Footer />
    </>
  );
};

export default Main;

//             {/*<div className=" z-10 fixed bottom-0 m-2">*/}
//                     {alertList.map(i => (
//                         i.msg == "-DONT-LOAD-ME-"? "" :
//                         i.ok ?
//                             <div className="alert alert-success shadow-lg">
//                                 <div>
//                                     <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6"
//                                          fill="none" viewBox="0 0 24 24">
//                                         <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
//                                               d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"/>
//                                     </svg>
//                                     <span>{i.msg}</span>
//                                 </div>
//                             </div>
//                             :
//                             <div className="alert alert-error shadow-lg">
//                                 <div>
//                                     <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6"
//                                          fill="none" viewBox="0 0 24 24">
//                                         <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
//                                               d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"/>
//                                     </svg>
//                                     <span>{i.msg}</span>
//                                 </div>
//                             </div>
//                     ))}

import React from 'react';

const TextInput = (props: TextInputProps) => {
    return (
        <div className={`form-control w-full px-0.5 ${props.className}`}>
            <label className="label">
                <span className="label-text">{props.labelRT}</span>
                <span className="label-text">{props.labelLT}</span>
            </label>

            <input name={props.name} type={props.type == undefined ? "text" : props.type} value={props.value} onChange={props.onChange}
                   className="input input-bordered w-full text-white placeholder-gray-400 " disabled={props.disabled}
                   placeholder={props.placeholder}/>

            <label className="label">
                <span className="label-text">{props.labelRB}</span>
                <span className="label-text">{props.labelLB}</span>
            </label>
        </div>
    );
};

interface TextInputProps {
    labelRT?: string,
    labelLT?: string,
    labelRB?: string,
    labelLB?: string,
    placeholder?: string,
    type?: string,
    className?: string,
    disabled?: boolean,
    name?: any,
    value?: any,
    onChange?: any,
}

export default TextInput;
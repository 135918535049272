import React, { ChangeEvent, useEffect, useState } from 'react';
import TextInput from "./TextInput";

const SaveModal = (props: SaveModalProps) => {
  const defaultMinute = 5
  const defaultSecond = 0
  const [valid, setValid] = useState(true)
  const [isRunning, setRunning] = useState(false)
  const [minuteValue, setMinuteValue] = useState(defaultMinute)
  const [secondValue, setSecondValue] = useState(defaultSecond)

  function getStyle(value: number): React.CSSProperties {
    // @ts-ignore
    return { "--value": value };
  }

  function updateCountDown() {
    if (secondValue === 0) {
      if (minuteValue === 0) {
        setRunning(false)
        setValid(false)
      } else {
        setMinuteValue(minuteValue - 1)
        setSecondValue(59)
      }
    } else {
      setSecondValue(secondValue - 1)
    }
  }

  async function sendVerification() {
    if (isRunning) return
    if (!props.validateData()) {
      document.getElementById('close-verify')?.click()
      return
    }
    props.setVerifyCode("")
    props.sendVerificationCode()
    setMinuteValue(defaultMinute)
    setSecondValue(defaultSecond)
    setValid(true)
    setRunning(true)
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (isRunning) updateCountDown()
    }, 1000);

    return () => clearInterval(interval);
  }, [secondValue, minuteValue, isRunning, valid]);

  return (
    <div>
      <div className="alert shadow-lg">
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
            className="stroke-info flex-shrink-0 w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
          </svg>
          <span>{props.infoText}</span>
        </div>
        <div className="flex-none">
          <label htmlFor="my-modal-4" className="btn btn-md btn-success modal-button w-full"
            onClick={sendVerification}>{props.openButton}</label>

        </div>

      </div>

      <input type="checkbox" id="my-modal-4" className="modal-toggle" />
      <label htmlFor="my-modal-4" className="z-30 modal cursor-pointer">
        <label className="modal-box relative bg-base-100/60 backdrop-blur-md" htmlFor="">
          <label id='close-verify' htmlFor="my-modal-4" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>

          <div className="flex gap-5 justify-center p-4">
            <div>
              <span className="countdown font-mono text-4xl">
                <span style={getStyle(minuteValue)} />
              </span>
              min
            </div>
            <div>
              <span className="countdown font-mono text-4xl">
                <span style={getStyle(secondValue)} />
              </span>
              sec
            </div>
          </div>

          <h3 className="text-lg font-bold">Verifizierungscode wurde zu ihrer Email gesendet.</h3>
          <p className="py-4">
            Bitte geben sie den Code hier ein, um die Daten zu speichern.
            Dies ist erfordelich um zu verifizieren das niemand anders versucht ihre Daten zu ändern.
          </p>


          <form className="px-2 sm:px-4 py-4 w-full max-w-6xl" onSubmit={(e) => {
            e.preventDefault()
            console.log(props.getAll)
            props.uploadChanges()
          }}>
            <div className="flex flex-col">

              <div className="px-1">
                {valid ?
                  ""
                  :
                  <>
                    <div className="flex justify-around pb-4">
                      <button className="btn btn-accent btn-sm" onClick={sendVerification}>Neuen
                        Code senden
                      </button>
                    </div>

                    <div className="alert alert-error shadow-lg">
                      <div>
                        <svg xmlns="http://www.w3.org/2000/svg"
                          className="stroke-current flex-shrink-0 h-6 w-6" fill="none"
                          viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        <span>Code ist abgelaufen!</span>
                      </div>
                    </div>
                  </>
                }
              </div>

              <TextInput name="code" value={props.verifyCode} onChange={(e: ChangeEvent) => //@ts-ignore
                props.setVerifyCode(e.target.value)}
                labelRT={"Veriefiezierungs code"}
                placeholder="123456" />

              <div className="px-0.5">
                <button type="submit" className="btn btn-primary w-full">Speichern
                </button>
              </div>
            </div>
          </form>


        </label>
      </label>

    </div>
  );
};

interface SaveModalProps {
  uploadChanges: any,
  sendVerificationCode: any,
  validateData: any,
  getAll?: any,
  verifyCode: any,
  setVerifyCode: any,
  infoText: string,
  openButton: string
}

export default SaveModal;

import React from 'react';
import TextInput from "./TextInput";

const ClubForm = (props: LoginUserFromProps) => {

    return (
        <div className="flex justify-around bg-base-300 rounded-b-2xl">
            <form className="px-2 sm:px-6 py-8 w-full max-w-6xl">
                <TextInput name="clubName" value={props.formValues.clubName} onChange={props.onChange}
                           labelRT={"Vereins- bzw. Gruppenname"}
                           placeholder="Muster Karneval eV"/>
                <TextInput name="owner" value={props.formValues.owner} onChange={props.onChange}
                           labelRT={"Name des Verantwortlichen"}
                           placeholder="Max Mustermann"/>
                <div className="flex justify-around">
                    <TextInput name="city" value={props.formValues.city} onChange={props.onChange}
                               labelRT={"Ort"} placeholder="Muster Stadt"/>
                </div>
            </form>
        </div>
    );
};

interface LoginUserFromProps {
    formValues: LoginUserFromValues,
    onChange: any
}

interface LoginUserFromValues {
    clubName: string,
    owner: string,
    city: string,
}

export default ClubForm;
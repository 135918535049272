import React from 'react';
import Main from "./views/Main";


function App() {
  return (
    <div className="App" data-theme="dark">
      <Main/>
    </div>
  );
}

export default App;
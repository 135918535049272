import React, {useState} from 'react';

const Collapse = (props: CollapseProps) => {

    const [open, setOpen] = useState(props.openByDefault? props.openByDefault : false);

    return (
        <div tabIndex={0} className="z-0 collapse collapse-plus border border-base-100 bg-base-300 rounded-box">
            <input type="checkbox" className="peer" checked={open} onChange={() => {setOpen(!open)}}/>

            <div className="collapse-title text-xl font-medium">
                {props.header}
            </div>
            <div className="collapse-content">
                {props.children}
            </div>
        </div>
    );
};

interface CollapseProps {
    header?: any,
    children?: any,
    openByDefault?: boolean
}

export default Collapse;
import React from 'react';

const Header = (props: HeaderProps) => {
    return (
        <>
            <header className="z-10 fixed w-screen bg-primary shadow-md p-3 flex justify-between items-center">
                <div className="px-4 flex justify-between items-center gap-4">
                    <img className="h-10 rounded-full" src={"/logo-akk.jpg"} alt={"Akk logo"}/>
                <h1 className="text-white text-xl font-bold ">{props.title}</h1>
                </div>
                <div className="px-4 flex justify-between items-center">
                    <p className="hidden sm:flex text-white px-4">{props.email}</p>
                    <div className="h-10 w-10 bg-white rounded-full flex justify-around items-center">
                        <p className="text-primary text-2xl">{props.email?.substring(1, -1).toUpperCase()}</p>
                    </div>
                </div>
            </header>

            <div className="w-1 h-16"></div>
        </>
    );
};

interface HeaderProps {
    title: string,
    email?: string
}

export default Header;

import React from 'react';

const Footer = () => {
    return (
        <footer>
            <div className="flex w-full justify-center py-10">
                <a href="https://akk-koblenz.de/datenschutz/">Datenschutz</a>
                <div className="divider divider-horizontal"></div>
                <a href="https://akk-koblenz.de/impressum/">Impressum</a>
            </div>
        </footer>
    );
};

export default Footer;

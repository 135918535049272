import React, { ChangeEvent, useState } from 'react';
import TextInput from "./TextInput";

const VehicleApprovalForm = (props: VehicleApprovalFormProps) => {

  return (
    <div className="flex justify-around bg-base-300 rounded-b-2xl">
      <form className="px-2 sm:px-6 py-8 w-full max-w-6xl">
        <div className="flex justify-around">
          <TextInput name="firstName" value={props.formValues.firstName} onChange={props.onChange} labelRT={"Vorname"}
            placeholder="Max" />
          <TextInput name="lastName" value={props.formValues.lastName} onChange={props.onChange} labelRT={"Nachname"}
            placeholder="Mustermann" />
        </div>
        <div className="flex justify-around">
          <TextInput name="street" value={props.formValues.street} onChange={props.onChange} labelRT={"Straße"}
            placeholder="Musterstraße" />
          <TextInput name="houseNumber" value={props.formValues.houseNumber} onChange={props.onChange}
            className="w-1/6" labelRT={"Hausnummer"} placeholder="123" />
        </div>
        <TextInput name="city" value={props.formValues.city} onChange={props.onChange} labelRT={"Ort"}
          placeholder="Muster Stadt" />
        <TextInput name="phoneNumber" value={props.formValues.phoneNumber} onChange={props.onChange} type="tel"
          labelRT={"Telefon/Fax/Mobil"} placeholder="02611234567" />
        <TextInput name="email" value={props.formValues.email} onChange={props.onChange} type="email" labelRT={"E-Mail"}
          placeholder="max@muster-mail.de" />
      </form>
    </div>
  );
};

interface VehicleApprovalFormProps {
  formValues: VehicleApprovalFormValues,
  onChange: any
}

interface VehicleApprovalFormValues {
  firstName: string,
  lastName: string,
  street: string,
  houseNumber: string,
  city: string,
  phoneNumber: string,
  email: string,
}

export default VehicleApprovalForm;

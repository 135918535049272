import _ from "lodash"
import { GroupsFormValues, GroupType } from "../components/GroupItem";

const url = "https://api-akk.jonasbe.de"

export class DataProvider {
  private loginUser: any;
  private setLoginUser: any;
  private vehicleApproval: any;
  private setVehicleApproval: any;
  private groupList: any;
  private setGroupList: any;
  private clubInfo: any;
  private setClubInfo: any;
  private verifyCode: string;
  private setActiveItemsByDefault: any;
  private alertList: any;
  private setAlertList: any;
  private verified: any;
  private setVerified: any;
  private setLoading: any;

  constructor(loginUser: any, setLoginUser: any, clubInfo: any, setClubInfo: any, vehicleApproval: any, setVehicleApproval: any, groupList: any, setGroupList: any, verifyCode: any, setActiveItemsByDefault: any, alertList: any, setAlertList: any, verified: any, setVerified: any, setLoading: any) {
    this.loginUser = loginUser
    this.setLoginUser = setLoginUser
    this.clubInfo = clubInfo
    this.setClubInfo = setClubInfo
    this.vehicleApproval = vehicleApproval
    this.setVehicleApproval = setVehicleApproval
    this.groupList = groupList
    this.setGroupList = setGroupList
    this.verifyCode = verifyCode
    this.setActiveItemsByDefault = setActiveItemsByDefault
    this.alertList = alertList
    this.setAlertList = setAlertList
    this.verified = verified
    this.setVerified = setVerified
    this.setLoading = setLoading

  }


  handleChange(event: any, formValue: any, setFunction: any) {
    const value = event.target.value;
    let updatedFormData = {
      ...formValue,
      [event.target.name]: value
    }
    setFunction(updatedFormData)
  }

  handleLoginUserFormChange(event: any) {
    this.handleChange(event, this.loginUser, this.setLoginUser)
  }

  handleClubFormChange(event: any) {
    this.handleChange(event, this.clubInfo, this.setClubInfo)
  }

  handleVehicleApprovalFormChange(event: any) {
    this.handleChange(event, this.vehicleApproval, this.setVehicleApproval)
  }

  handleGroupsFormChange(id: number, name: string, value: any) {
    const newItems = [...this.groupList]
    const item = newItems.find(todo => todo.id === id)
    item[name] = value
    console.log(id, name, value)
    console.log(newItems)
    this.setGroupList(newItems)
  }

  addEmptyGroupItem() {
    const idForItem = this.groupList[this.groupList.length - 1] == undefined ? 1 : this.groupList[this.groupList.length - 1].id + 1
    const item = {
      id: idForItem,
      theme: "",
      music: false,
      lengthCount: "",
      groupType: "FootGroup",
      kowadi: "0",
      self: "0"
    };
    const newItems = [...this.groupList, item]
    this.setGroupList(newItems)
    this.setActiveItemsByDefault([idForItem])
  }

  deleteGroupItem(id: number) {
    console.log("delete Item: " + id)
    const newItems = this.groupList.filter((i: { id: number; }) => i.id != id)
    for (let i = 0; i < newItems.length; i++) {
      newItems[i].id = i + 1
    }
    this.setGroupList(newItems)
  }

  reorderGroupItems(id: number, direction: "up" | "down") {
    let newItems: GroupsFormValues[] = _.cloneDeep(this.groupList)
    newItems = newItems.sort((a, b) => a.id - b.id)
    const item = newItems.find((todo) => todo.id === id)
    if (item == undefined) return
    const index = newItems.indexOf(item)
    if (direction == "up") {
      if (index == 0) return
      newItems[index] = newItems[index - 1]
      newItems[index - 1] = item
    } else {
      if (index == newItems.length - 1) return
      newItems[index] = newItems[index + 1]
      newItems[index + 1] = item
    }
    newItems.map((item, index) => {
      item.id = index + 1
    })
    this.setGroupList(newItems)
  }

  buildJSON(): string {
    return JSON.stringify({
      verifyCode: this.verifyCode,
      loginUser: this.loginUser,
      clubInfo: this.clubInfo,
      vehicleApproval: this.vehicleApproval,
      groupList: this.groupList
    })
  }

  validateData(): boolean {
    if (!this.verified) {
      return true
    }
    let valid = true
    if (this.loginUser.firstName == "" ||
      this.loginUser.lastName == "" ||
      this.loginUser.street == "" ||
      this.loginUser.houseNumber == "" ||
      this.loginUser.city == "" ||
      this.loginUser.phoneNumber == ""
    ) {
      alert("Bitte fuelle alle Felder in 'Verantwortlicher fuer die Anmeldung' aus!")
      valid = false
    }
    if (this.clubInfo.clubName == "" ||
      this.clubInfo.owner == "" ||
      this.clubInfo.city == ""
    ) {
      alert("Bitte fuelle alle Felder in 'Verein' aus!")
      valid = false
    }

    // @ts-ignore
    const hasVehicle = this.groupList.some((e: GroupsFormValues) => e.groupType !== "FootGroup")
    if (hasVehicle) {
      if (this.vehicleApproval.firstName == "" ||
        this.vehicleApproval.lastName == "" ||
        this.vehicleApproval.street == "" ||
        this.vehicleApproval.houseNumber == "" ||
        this.vehicleApproval.city == "" ||
        this.vehicleApproval.phoneNumber == "" ||
        this.vehicleApproval.email == ""
      ) {
        alert("Bitte fuelle alle Felder in 'Verantwortlicher fuer die Abnahme' aus!")
        valid = false
      }
    }
    for (let i = 0; i < this.groupList.length; i++) {
      const item = this.groupList[i]
      if (item.theme == "" || item.lengthCount == "") {
        alert("Bitte fuelle alle Felder in der Gruppe '" + item.theme + "' aus!")
        valid = false
      }
      if (item.groupType != "FootGroup") {
        if ((item.kowadi === "" || item.kowadi === "0") && (item.self === "" || item.self === "0")) {
          alert("Bitte hinterlege mindestens einen Ordner fuer das Fahrzeug '" + item.theme + "'!")
          valid = false
        }
      }
    }
    console.log("valid: " + valid)
    return valid
  }


  //    ------API Requests------

  async sendVerification(email: string) {
    this.setLoading(true)
    try {
      let res = await fetch(`${url}/send-code?email=${email}`, {
        method: "GET",
      })
      let data = await res.json()
      if (this.alerting(data)) {
        window.location.reload();
      }
    } catch (e) {
      alert("Ein unerwarteter Fehler ist aufgetreten versuche es in ein paar Minuten nochmal!")
      window.location.reload();
    } finally {
      this.setLoading(false)
    }
  }

  async uploadChanges() {
    this.setLoading(true)

    try {
      let res = await fetch(`${url}/upload`, {
        method: "POST",
        body: this.buildJSON()
      })
      let data = await res.json()
      this.alerting(data)
      console.log(res.status)
      if (res.status.toString().startsWith("2")) {
        window.location.reload();
      }
    } catch (e) {
      alert("Ein unerwarteter Fehler ist aufgetreten versuche es in ein paar Minuten nochmal!")
    } finally {
      this.setLoading(false)
    }
  }

  async pullData() {
    this.setLoading(true)
    try {
      let res = await fetch(`${url}/pull?email=${this.loginUser.email}&code=${this.verifyCode}`, {
        method: "GET",
      })
      let data = await res.json()
      this.alerting(data)
      if (!res.ok) return
      data = data.data

      this.setLoginUser(data.loginUser)
      this.setClubInfo(data.clubInfo)
      this.setVehicleApproval(data.vehicleApproval)
      this.setGroupList(data.groupList == null ? [{
        id: 1,
        theme: "",
        lengthCount: "",
        groupType: "FootGroup",
        kowadi: "0",
        self: "0"
      },] : data.groupList)
      this.setVerified(true)
    } catch (e) {
      alert("Ein unerwarteter Fehler ist aufgetreten versuche es in ein paar Minuten nochmal!")
      window.location.reload();
    } finally {
      this.setLoading(false)
    }
  }

  alerting(msg: string): boolean {
    console.log(JSON.stringify(msg))
    // @ts-ignore
    if (msg.alert !== undefined) { // @ts-ignore
      alert(msg.alert)
      return true
    }
    return false
  }
}

import React from 'react';
import GroupItem, { GroupsFormValues } from "./GroupItem";

const GroupsTable = (props: GroupsFormProps) => {


  return (
    <div className="my-2 p-2 rounded-xl bg-accent-content/10">
      <h3 className="text-center font-bold text-xl text-white p-2">Gruppen</h3>
      <ul>
        {props.formValues.map(i => (
          <li key={i.id} className="my-0.5">
            <GroupItem openByDefault={props.openItemsByDefault?.includes(i.id)} formValue={i} onChange={props.onChange} reorderGroupItems={props.reorderGroupItems} deleteGroupItem={props.deleteGroupItem} />
          </li>
        ))}

      </ul>
      <button className="mt-3 btn btn-primary w-full" onClick={() => props.addEmptyGroupItem()}>
        Weitere Hinzufügen
      </button>
    </div>
  );
};


interface GroupsFormProps {
  formValues: GroupsFormValues[],
  onChange: any,
  reorderGroupItems: any,
  openItemsByDefault?: number[],
  addEmptyGroupItem: any,
  deleteGroupItem: any
}



export default GroupsTable;
